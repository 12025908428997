import { Text } from '@youversion/design-system-components';
import { InfoCircle } from '@youversion/design-system-icons';
import { GetStaticProps, NextPage } from 'next';
import { useRouter } from 'next/compat/router';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import { PageType, PageWrapper } from '@/components/PageWrapper';

const Custom404Page: NextPage = () => {
  const router = useRouter();
  const { t } = useTranslation('custom404');

  return (
    <PageWrapper disableNav={true} disableStatusRibbons={true} pageType={PageType.Root}>
      <div
        className='flex w-full items-center justify-center h-fit'
        style={{
          // Calculated by adding (72px from Navbar) + (16 x 2 from top and bottom padding of contentWrapper)
          minHeight: 'calc(100vh - 104px)',
        }}
      >
        <div className='flex flex-col items-center gap-1'>
          <InfoCircle className='dark:text-tertiary-dark text-tertiary-light' size='64px' />
          <Text className='text-center' size='xs' variant='headline'>
            {t('something_wrong')}
          </Text>
          <Text className='text-center' size='md' variant='caption'>
            {t('error_code')}
          </Text>
          <div className='mbs-2'>
            <Text className='cursor-pointer underline text-info' onClick={router?.back} size='md' variant='paragraph'>
              {t('go_back')}
            </Text>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale!, ['custom404', 'common'])),
    },
  };
};

export default Custom404Page;
